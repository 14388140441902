/* Box Shadow */
.boxShadow {
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.15),
    0 2px 4px rgba(0, 0, 0, 0.12);
}
.boxShadowHover {
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.15),
    0 2px 4px rgba(0, 0, 0, 0.12);
}
.boxShadowHover:hover {
  box-shadow:
    0 3px 6px 1px var(--orange2),
    0 3px 5px 1px var(--orange3);
}

.thumbnail {
  max-width: 250px;
  min-width: 250px;
  width: 250px;
  max-height: 215px;
  min-height: 200px;
  height: auto;
}
.error {
  color: var(--errorRed);
  background-color: var(--lightRed);
  text-align: center;
  border-radius: 15px;
  font-weight: bold;
  padding: 0.5rem 1rem;
}
.success {
  color: var(--successGreen);
  background-color: var(--lightGreen);
  text-align: center;
  border-radius: 15px;
  font-weight: bold;
  padding: 0.5rem;
}
.correct {
  color: var(--successGreen);
  background-color: var(--lightGreen);
  border-radius: 5px;
  min-width: 100%;
  border: 2px solid var(--successGreen);
  padding: 0.3rem 0.6rem;
}
.correct p {
  font-weight: 800;
}
.incorrect {
  color: var(--errorRed);
  background-color: var(--lightRed);
  border-radius: 5px;
  min-width: 100%;
  border: 2px solid var(--errorRed);
  padding: 0.3rem 0.6rem;
}
.incorrect p {
  font-weight: 800;
}
.detail {
  font-size: 1rem;
  color: var(--orange4);
}
.complete {
  border-left: 18px solid hsla(118, 39%, 37%, 0.9);
}
.incomplete {
  border-left: 18px solid hsla(46, 96%, 57%, 0.9);
}
.gapIt {
  margin-right: 1.5rem;
}

/* For the main icons */
.icon {
  height: 25px;
  width: 25px;
}
.icon-check {
  height: 35px;
  width: 35px;
}
.iconMenu {
  height: 35px;
  width: 35px;
}
.nav-icon {
  height: 45px;
  width: 45px;
}
#icon-home {
  height: 35px;
  width: 35px;
}
/* For regular icons */
.primary {
  fill: var(--orange3);
}
.primary:hover {
  fill: var(--orange5);
}
.secondary {
  fill: var(--yellow3);
}
.secondary:hover {
  fill: var(--yellow5);
}
/* For button icons */
.primaryIcon {
  fill: var(--orange5);
}
.primaryIcon:hover {
  fill: var(--orange1);
}
.secondaryIcon {
  fill: var(--orange1);
}
.secondaryIcon:hover {
  fill: var(--yellow1);
}
.selectedAnswer {
  background-color: var(--yellow1);
  width: 100%;
  border: 2px solid var(--yellow2) !important;
  box-shadow: 0 0 2px 1px var(--yellow1);
}
.icon-asterisk {
  height: 15px;
  width: 15px;
  margin-bottom: 0.5rem;
  margin-left: 0.2rem;
}
.delete-icon {
  fill: var(--errorRed);
  height: 30px;
  width: 30px;
}

/* For wiggle pic */
.wigglePic {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-size: cover;
  background-color: #f6d29d;
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23e68b07' fill-opacity='0.58'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
canvas {
  margin: 0 auto;
  display: block;
  max-width: 100%;
  height: auto;
}