/* SMALLEST SCREENS */
@media screen and (width <= 768px){
    /* body{
        font-size: 16px;
        overflow-x: hidden;
      } */
      /* 1.067 Minor Second with a 16px body */
      p{
        font-size: 1rem;
      }
      span, .italicSpan{
        font-size: 0.9rem;

      }
      a{
        font-size: 1rem;
        font-family: 'Noto_Sans', sans-serif;
      }
      /* main{
        height: 100vh;
      } */
      textarea{
        resize: none;
        height: 50px;
        width: 150px;
      }
      label{
        font-size: 1rem;
      }
      .thumbnail{
        max-width: 200px;
        min-width: 200px;
        width: 200px;
        max-height: 150px;
        min-height: 150px;
        height: auto;
      }
      .icon{
        height: 30px;
        width: 30px;
      }
      .iconMenu{
        height: 25px;
        width: 25px;
      }
}

/* Base font size for the entire document */
html {
  font-size: 16px; /* 1rem = 16px */
}

/* Small Screens (Mobile) */
@media screen and (min-width: 300px) and (max-width: 600px) {
  body {
    overflow-x: hidden;
  }
    /* border: 1px solid red; */
  /* }
  label {
    font-size: 0.875rem; /* 14px */
  /* } */
  button {
    font-size: 1rem; /* 16px */
  }
  h1{
    font-size: 1.3rem;
  }
  h2{
    font-size: 1.2rem;
  }
  h3{
    font-size: 1.1rem;
  }
  h4{
    font-size: 1.15rem;
  }
  h5{
    font-size: 1.1rem;
  }
  h6{
    font-size: 1rem;
  }
  p {
    font-size: 1rem;
  }
  a {
    font-size: 0.8rem;
  }
  .iconMenu{
    height: 25px;
    width: 25px;
  }
}

/* Medium Screens (Tablet) */
@media screen and (min-width: 601px) and (max-width: 1440px) {
  body {
    font-size: 16px; /* 18px */
    /* border: 1px solid blue; */
  }
  p {
    font-size: 1rem; /* 18px */
  }
  span {
    font-size: 1rem;
  }
  label {
    font-size: 1rem; /* 16px */
  }
  button {
    font-size: 1.125rem; /* 18px */
  }
  h1{
    font-size: 1.55rem;
  }
  h2{
    font-size: 1.45rem;
  }
  h3{
    font-size: 1.35rem;
  }
  h4{
    font-size: 1.25rem;
  }
  h5{
    font-size: 1.15rem;
  }
  h6{
    font-size: 1.05rem;
  } 
  a {
    font-size: 0.8rem;
  }
}

/* Large Screens (Desktop) */
@media screen and (min-width: 1440px) {
  /* body {
    border: 1px solid purple;
  } */
  h1 {
    font-size: 2.5rem; /* 50px */
  }
  h2 {
    font-size: 2.25rem; /* 45px */
    
  }
  h3 {
    font-size: 2rem; /* 40px */
  }
  h4 {
    font-size: 1.75rem; /* 35px */
  }
  h5 {
    font-size: 1.625rem; /* 32.5px */
  }
  h6 {
    font-size: 1.5rem; /* 30px */
  }
  p {
    font-size: 1.25rem; /* 20px */
  }
  label {
    font-size: 1.125rem; /* 18px */
  }
  button {
    font-size: 1.25rem; /* 20px */
  } 
  a {
    font-size: 1rem;
  }
  /* h1{
    font-size: 1.476rem;
  }
  h2{
    font-size: 1.383rem;
  }
  h3{
    font-size: 1.296rem;
  }
  h4{
    font-size: 1.215rem;
  }
  h5{
    font-size: 1.138rem;
  }
  h6{
    font-size: 1.067rem;
  }  */
}